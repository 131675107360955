.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-alt);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  margin-bottom: 1rem;
}

.portfolio__item:hover {
  border-color: var(--color-primary-alt);
  background: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item-title {
  display: flex;
  justify-content: center;
}

.portfolio__item-title>a{
color: var(--color-white);
transition: var(--transition);
}

.portfolio__item-title>a:hover {
  color: var(--color-primary)
}

.portfolio__item h3 {
  margin: 1.2rem 0 0;
}

.portfolio__item__stack-icon__container {
  font-size: .75rem;
  margin: .5rem 0 .5rem;

}

.portfolio__item-call-to-action {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.portfolio__item__stack-icon__container {
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}