#about {
  margin-top: 1rem;
}

.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
}

.about__me-pfp-container{
  width: 100%;
  border-radius: 2rem;
  background: linear-gradient(45deg,
      transparent,
      var(--color-primary-alt),
      transparent);
  display: grid;
  place-items: center;
}

.about__me {
display: flex;
align-items: center;
}

.about__me-pfp {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(5deg);
  transition: var(--transition);
}

.about__me-pfp:hover {
  transform: rotate(-5deg);
}

.about__icon {
  margin-bottom: 1rem;
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light)
}

.about__contact-btn-container {
  width: 80%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about content p {
    margin: 1rem 0 1.5rem;
  }
}

@media screen and (max-width: 600px) {
  .about__me {
    width: 80%;
    margin: 0 auto 3rem;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }

  .about__contact-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

